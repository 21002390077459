<template>
  <div>
    <v-card-text>
      <div v-if="items.length == 0">
        <v-progress-linear
          color="primary"
          indeterminate
          height="6"
          v-show="progress"
        ></v-progress-linear>
        <v-alert text type="info">No hay data para mostrar</v-alert>
      </div>
      <div v-else @click.right.prevent @copy.prevent @paste.prevent>
        <v-card outlined class="white pa-2">
          <v-data-table
             :headers="tarifarioHeader"
             :items="filteredData"
              loading-text="Consultando... favor espere"
          >
          <template
            v-for="(header, i) in tarifarioHeader"
            v-slot:[`header.${header.value}`]="{ }"
          >
            {{ header.text }}
            <div @click.stop :key="i">
              <v-autocomplete
                :key="i"
                :items="items"
                 :item-text = header.value
                 :item-value = header.value
                v-model="multiSearch[header.value]"
                class="pa"
                type="text"
                multiple
                :placeholder="header.value"
              ></v-autocomplete>
            </div>
        </template>
          <template v-slot:item.Comision="{ item }">{{
                            item.Comision | numeric
                          }}</template>
           <template v-slot:item.ComisionPC="{ item }">{{
                            item.ComisionPC | numeric
                          }}</template>
          </v-data-table>
        </v-card>
      </div>
      <br />
      <v-alert type="warning" text class="">
        Los datos aquí presentados son una guía para la interpretación del pago
        de comisiones. Por sí mismo no representan un acuerdo definitivo entre
        las Partes. LA COMPAÑÍA podrá modificar las reglas y montos de
        comisiones de conformidad a los términos y condiciones del Contrato, sin
        embargo, la actualización del Instructivo pudiera no realizarse con la
        misma frecuencia con que se implementan dichas modificaciones.
      </v-alert>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-form ref="form" lazy-validation>
          <v-autocomplete
            :items="tipoProducto"
            :filter="customFilter"
            v-model="tipoProductoSelected"
            item-text="ProductoName"
            item-value="ProductoTipoId"
            placeholder="Tipos de productos"
            multiple
            append-icon="keyboard_arrow_down"

          ></v-autocomplete>
          <v-autocomplete
            :items="listProductoCodes"
            :filter="customFilter"
            v-model="productoCodesSelected"
            item-text="ProductName"
            item-value="ProductCodeID"
            placeholder="Price plan"
            
            multiple
            append-icon="keyboard_arrow_down"
          ></v-autocomplete>
          <!-- </div>
            </v-col>
          </v-row> -->
        </v-form>
        <br />
        <div class="mb-5 text-right">
          <v-btn
            block
            class="ma-1"
            depressed
            color="primary"
            @click="getTarifarios"
          >
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
          <v-btn
              block
              depressed
              :loading="loadingDownload"
              @click="download"
            >
              Exportar
            </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiEsquema from "@/api/esquemas";
import apiProducto from "@/api/productos";
import download from "downloadjs";
// import { global } from "@/api/global";

export default {
  data: () => ({
    drawerRight: true,
    items: [],
    tarifarioHeader: [
    { text: "PRODUCTO", value: "NombreProducto" },
		{ text: "TIPO PRODUCTO", value: "TipoProducto" },
		{ text: "CODIGO PRODUCTO", value: "ProductCode" },
		{ text: "CONCEPTO", value: "Concepto" },
		{ text: "COMISION", value: "Comision" },
		{ text: "COMISION PC", value: "ComisionPC" },
		{ text: "GAMA", value: "Gama" },
    ],
    progress: false,
    loadingDownload: false,
    tipoProductoSelected: "",
    listProductoCodes: [],
    productoCodesSelected: "",
    customFilter(item, queryText) {
      const hasValue = (val) => (val != null ? val : "");
      const text = hasValue(item.ProductoName) || hasValue(item.ProductCode);
      const query = hasValue(queryText);
      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    multiSearch: {},
  }),
  computed: {
    tipoProducto() {
      return this.$store.getters.tipoProducto;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    filteredData() {
        if (this.multiSearch) {
        let objectKey =(Object.keys(this.multiSearch))
        let newItem = {};
          if(objectKey.length > 0)  
        {
          for(let i=0; i < objectKey.length; i++)
        {
           let key = objectKey[i];
          let result = this.multiSearch[key].reduce((p,c)=>{
            return p + c + '|';
           },"");

           newItem[key] = result.substring(0,result.length -1);
        }
        return this.items.filter((item) => {
          return Object.entries(newItem).every(([key, value]) => {
                    if (value.includes("|") && !value.includes("!")) {
                        let el = value.split("|");
                        return el.some((elem) =>
                            (item[key] || "").toString().toUpperCase().startsWith(elem.toString().toUpperCase())
                        );
                    }
                    if (value.substring(0, 1) === "!" && !value.includes("|")) {
                        let el = value.split("!");
                        return el.some((elem) =>
                            !(item[key] || "").toString().toUpperCase().startsWith(elem.toString().toUpperCase())
                        );
                    }
                    if (value.includes("|") && value.substring(0, 1) === "!") {
                        let el = value.split("!")[1].split("|");
                        return !el.some((elem) =>
                            (item[key] || "").toString().toUpperCase().startsWith(elem.toString().toUpperCase())
                        );
                    }
                    if (value.substring(0, 1) === ">") {
                        let el = value.split(">");
                        if (item[key] !== " ") {
                          return Number(item[key] || "") > el[1];
                        }
                    }
                    if (value.substring(0, 1) === "<") {
                        let el = value.split("<");
                        if (item[key] !== " ") {
                          return Number(item[key] || "") < el[1];
                        }
                    }
                    if (value.substring(0, 1) === "=") {
                        let el = value.split("=");
                        return (item[key] || "").toString().toUpperCase() === el[1].toString().toUpperCase();
                    }
                    return (item[key] || "").toString().toUpperCase().includes(value.toString().toUpperCase());
                });
            });

        }
        return this.items; 
        } else {
        return this.items;
        }
    },
  },
  methods: {
    download() {

      if (!this.$refs.form.validate()){
        return;
      }

      let param = {
          canalAsociado: this.currentUser.asociado.canalAsociadoId,
          codigosProductosList: this.productoCodesSelected,
          tipoProducto: this.tipoProductoSelected,
        };


      this.loadingDownload = true;
      apiEsquema
        .getReporteTarifarioExport(param)
        .then((response) => {
          download(response.data, "ComisionesPorProducto" + ".xlsx");
          this.loadingDownload = false;
        })
        .catch((error) => {
          this.setError(error);
          this.loadingDownload = false;
        });
    },
    getTarifarios() {
      if (this.$refs.form.validate()) {
        this.progress = true;

        let param = {
          canalAsociado: this.currentUser.asociado.canalAsociadoId,
          codigosProductosList: this.productoCodesSelected,
          tipoProducto: this.tipoProductoSelected,
        };

        apiEsquema
          .getReporteTarifario(param)
          .then((response) => {
            this.items = response.data;
            this.progress = false;
          })
          .catch((error) => {
            this.$store.dispatch("setError", true);
            this.$store.dispatch("setErrorMessage", error.message);
          });
      }
    },
    refreshProductCodes() {
      this.productoCodesSelected = "";

      apiProducto
        .GetProductCodesByTipoID(this.tipoProductoSelected)
        .then((response) => {
          this.listProductoCodes = response.data;
        })
        .catch((error) => {
          this.setError(error);
        });
    },
  },
  watch: {
    tipoProductoSelected: function () {
      this.refreshProductCodes();
    },
  },
  mounted() {
    this.refreshProductCodes();
  },
};
</script>